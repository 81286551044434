/* eslint-disable @typescript-eslint/no-explicit-any */
import { HTTPError } from "ky-universal";
import { NormalizedOptions } from "ky/distribution/types/options";

export default class HTTPErrorWithBody extends HTTPError {
  responseJson: unknown;

  constructor(
    response: Response,
    request: Request,
    options: NormalizedOptions,
    responseJson: unknown
  ) {
    super(response, request, options);
    this.responseJson = responseJson;
  }
}
