import { TranslationKeys } from "src/@types/i18next";
import FSAGeographicArea from "./FSAGeographicArea";

export interface IProvince {
  id: string;
  name: string;
  postalAbbreviation: string;
}

export type Province = {
  id: number;
  abbr: string;
  name: TranslationKeys["common"];
  fsaAreas: FSAGeographicArea[];
};

export enum ProvinceEnum {
  Alberta = 1,
  BritishColombia = 2,
  Manitoba = 3,
  NewBrunswick = 4,
  Newfoundland = 5,
  NovaScotia = 6,
  Ontario = 7,
  PrinceEdwardIsland = 8,
  Quebec = 9,
  Saskatchewan = 10,
  NorthwestTerritories = 11,
  Yukon = 12,
  Nunavut = 13,
}

export const provinces: Province[] = [
  {
    id: 1,
    abbr: "AB",
    name: "common:province_alberta",
    fsaAreas: [FSAGeographicArea.Alberta],
  },
  {
    id: 2,
    abbr: "BC",
    name: "common:province_british_columbia",
    fsaAreas: [FSAGeographicArea.BritishColombia],
  },
  {
    id: 3,
    abbr: "MB",
    name: "common:province_manitoba",
    fsaAreas: [FSAGeographicArea.Manitoba],
  },
  {
    id: 4,
    abbr: "NB",
    name: "common:province_new_brunswick",
    fsaAreas: [FSAGeographicArea.NewBrunswick],
  },
  {
    id: 5,
    abbr: "NL",
    name: "common:province_newfoundland",
    fsaAreas: [FSAGeographicArea.Newfoundland],
  },
  {
    id: 6,
    abbr: "NS",
    name: "common:province_nova_scotia",
    fsaAreas: [FSAGeographicArea.NovaScotia],
  },
  {
    id: 7,
    abbr: "ON",
    name: "common:province_ontario",
    fsaAreas: [
      FSAGeographicArea.EasternOntario,
      FSAGeographicArea.CentralOntario,
      FSAGeographicArea.MetropolitanToronto,
      FSAGeographicArea.NorthernOntario,
      FSAGeographicArea.SouthwesternOntario,
    ],
  },
  {
    id: 8,
    abbr: "PE",
    name: "common:province_prince_edward_island",
    fsaAreas: [FSAGeographicArea.PrinceEdwardIsland],
  },
  {
    id: 9,
    abbr: "QC",
    name: "common:province_quebec",
    fsaAreas: [
      FSAGeographicArea.EasternQuebec,
      FSAGeographicArea.WesternQuebec,
      FSAGeographicArea.MetropolitanMontreal,
    ],
  },
  {
    id: 10,
    abbr: "SK",
    name: "common:province_saskatchewan",
    fsaAreas: [FSAGeographicArea.Saskatchewan],
  },
  {
    id: 11,
    abbr: "NT",
    name: "common:province_northwest_territories",
    fsaAreas: [FSAGeographicArea.NorthwestTerritories],
  },
  {
    id: 12,
    abbr: "YT",
    name: "common:province_yukon",
    fsaAreas: [FSAGeographicArea.Yukon],
  },
  {
    id: 13,
    abbr: "NU",
    name: "common:province_nunavut",
    fsaAreas: [FSAGeographicArea.Nunavut],
  },
];

export function getProvinceAbbr(province: string | undefined) {
  const exists: ProvinceEnum =
    ProvinceEnum[province as keyof typeof ProvinceEnum];

  if (!province && !exists) {
    return undefined;
  }

  return provinces.find((p) => p.id === exists)?.abbr;
}

export function getProvinceByAbbr(abbr: string) {
  if (!abbr) return undefined;

  return provinces.find((p) => p.abbr === abbr.toUpperCase());
}

export function getProvinceById(id: number) {
  return provinces.find((p) => p.id === id);
}
