import { HTTPError, ResponsePromise } from "ky-universal";
import { KyInstance } from "ky/distribution/types/ky";
import { Input, Options } from "ky/distribution/types/options";
import HTTPErrorWithBody from "./HTTPErrorWithBody";
import HTTPErrorWithProblemDetails from "./HTTPErrorWithProblemDetails";
import { ProblemDetails } from "./ProblemDetails";

const isProblemDetails = (value: unknown): value is ProblemDetails => {
  const maybePd = value as ProblemDetails;

  return (
    typeof maybePd.status === "number" &&
    typeof maybePd.title === "string" &&
    typeof maybePd.type === "string"
  );
};

function adjustOptions(options?: Options) {
  const beforeErrorHook = async function beforeErrorHook(error: HTTPError) {
    if (error.response) {
      try {
        const body = await error.response.json();

        if (isProblemDetails(body)) {
          return new HTTPErrorWithProblemDetails(
            error.response,
            error.request,
            error.options,
            body
          );
        }

        return new HTTPErrorWithBody(
          error.response,
          error.request,
          error.options,
          body
        );
      } catch (e) {
        // ignore
      }
    }
    return error;
  };

  return {
    ...options,
    hooks: {
      ...options?.hooks,
      beforeError: [beforeErrorHook],
    },
  };
}

// Note: The order of the below "await response" then "await json" calls is intentional
// and resolves an issue with server-side Node.js API calls hanging due to an issue with
// internal clone calls stalling when response > 16kB. It should have been resolved
// with ky-universal setting the highWaterMark but it's not. See post here:
// https://github.com/sindresorhus/ky-universal/issues/8#issuecomment-509789901

const base = {
  rawGet: (
    kyInstance: KyInstance,
    url: Input,
    options?: Options
  ): ResponsePromise => {
    return kyInstance.get(url, adjustOptions(options));
  },
  rawPost: (
    kyInstance: KyInstance,
    url: Input,
    options?: Options
  ): ResponsePromise => {
    return kyInstance.post(url, adjustOptions(options));
  },
  rawPut: (
    kyInstance: KyInstance,
    url: Input,
    options?: Options
  ): ResponsePromise => {
    return kyInstance.put(url, adjustOptions(options));
  },
  rawDelete: (
    kyInstance: KyInstance,
    url: Input,
    options?: Options
  ): ResponsePromise => {
    return kyInstance.delete(url, adjustOptions(options));
  },
  get: async <T = unknown>(
    kyInstance: KyInstance,
    url: Input,
    options?: Options
  ): Promise<T> => {
    const response = await base.rawGet(kyInstance, url, options);
    const json = await response.json<T>();
    return json;
  },
  post: async <T = unknown>(
    kyInstance: KyInstance,
    url: Input,
    options?: Options
  ): Promise<T> => {
    const response = await base.rawPost(kyInstance, url, options);
    const json = await response.json<T>();
    return json;
  },
  put: async <T = unknown>(
    kyInstance: KyInstance,
    url: Input,
    options?: Options
  ): Promise<T> => {
    const response = await base.rawPut(kyInstance, url, options);
    const json = await response.json<T>();
    return json;
  },
  delete: async <T = unknown>(
    kyInstance: KyInstance,
    url: Input,
    options?: Options
  ): Promise<T> => {
    const response = await base.rawDelete(kyInstance, url, options);
    const json = await response.json<T>();
    return json;
  },
};

export default base;
