import processEnvironment from "@modules/core/processEnvironment";
import ky, { ResponsePromise } from "ky-universal";
import { Input, Options } from "ky/distribution/types/options";
import base from "./base";

export const getPrefixUrl = () => {
  if (process.env.NODE_ENV === "test") return process.env.VITE_PUBLIC_API;
  if (processEnvironment === "server") return process.env.SERVER_API;
  return process.env.NEXT_PUBLIC_API;
};

const kyInstance = ky.create({
  prefixUrl: getPrefixUrl(),
  retry: 0,
  timeout: 30000,
  headers: {
    "X-CSRF-TOKEN": process.env.NEXT_PUBLIC_CSRF_TOKEN_VALUE,
  },
});

const api = {
  rawGet: (url: Input, options?: Options): ResponsePromise => {
    return base.rawGet(kyInstance, url, options);
  },
  rawPost: (url: Input, options?: Options): ResponsePromise => {
    return base.rawPost(kyInstance, url, options);
  },
  rawPut: (url: Input, options?: Options): ResponsePromise => {
    return base.rawPut(kyInstance, url, options);
  },
  rawDelete: (url: Input, options?: Options): ResponsePromise => {
    return base.rawDelete(kyInstance, url, options);
  },
  get: async <T = unknown>(url: Input, options?: Options): Promise<T> => {
    const res = await base.get(kyInstance, url, options);
    return res as Promise<T>;
  },
  post: async <T = unknown>(url: Input, options?: Options): Promise<T> => {
    const res = await base.post(kyInstance, url, options);
    return res as Promise<T>;
  },
  put: async <T = unknown>(url: Input, options?: Options): Promise<T> => {
    const res = await base.put(kyInstance, url, options);
    return res as Promise<T>;
  },
  delete: async <T = unknown>(url: Input, options?: Options): Promise<T> => {
    const res = await base.delete(kyInstance, url, options);
    return res as Promise<T>;
  },
};

export default api;
