enum FSAGeographicArea {
  Newfoundland = "A",
  NovaScotia = "B",
  PrinceEdwardIsland = "C",
  NewBrunswick = "E",
  EasternQuebec = "G",
  MetropolitanMontreal = "H",
  WesternQuebec = "J",
  EasternOntario = "K",
  CentralOntario = "L",
  MetropolitanToronto = "M",
  SouthwesternOntario = "N",
  NorthernOntario = "P",
  Manitoba = "R",
  Saskatchewan = "S",
  Alberta = "T",
  BritishColombia = "V",
  NorthwestTerritories = "X",
  Nunavut = "X",
  Yukon = "Y",
}

export default FSAGeographicArea;
