import { NormalizedOptions } from "ky/distribution/types/options";
import HTTPErrorWithBody from "./HTTPErrorWithBody";
import { ProblemDetails } from "./ProblemDetails";

export default class HTTPErrorWithProblemDetails extends HTTPErrorWithBody {
  problemDetails: ProblemDetails;

  constructor(
    response: Response,
    request: Request,
    options: NormalizedOptions,
    problemDetails: ProblemDetails
  ) {
    super(response, request, options, problemDetails);
    this.problemDetails = problemDetails;
  }
}
